import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'wb-marketplace',
});

export const productsCatalogRouteRef = createSubRouteRef({
  id: 'wb-marketplace-dp-catalog',
  parent: rootRouteRef,
  path: '/list',
});
export const searchHomepageRef = createSubRouteRef({
  id: 'wb-marketplace-search',
  parent: rootRouteRef,
  path: '/home',
});
export const dataProductRouteRef = createSubRouteRef({
  id: 'wb-marketplace:dataproduct',
  parent: rootRouteRef,
  path: '/search/:id/*',
});

export const dataProductLegacyRouteRef = createSubRouteRef({
  id: 'wb-marketplace:dataproduct',
  parent: rootRouteRef,
  path: '/products-catalog/:id/*',
});

export const outputPortRouteRef = createSubRouteRef({
  id: 'wb-marketplace:outputPort',
  parent: rootRouteRef,
  path: '/consumable-interface/:id/*',
});

export const productsGraphRouteRef = createSubRouteRef({
  id: 'wb-marketplace-dp-graph',
  parent: rootRouteRef,
  path: '/visual-discovery-old',
});

// route for the visual discovery
export const visualDiscovery = createSubRouteRef({
  id: 'wb-marketplace-visual-discovery',
  parent: rootRouteRef,
  path: '/visual-discovery',
});

export const dataContractsRouteRef = createSubRouteRef({
  id: 'wb-marketplace-data-contracts',
  parent: rootRouteRef,
  path: '/data-contracts-discovery',
});

// route for the revamped data contract page
export const legacyDataContractsPage = createSubRouteRef({
  id: 'wb-marketplace-data-contracts-legacy',
  parent: rootRouteRef,
  path: '/data-contracts',
});

// route for the high level data contracts graph
export const highLevelDataContractsPage = createSubRouteRef({
  id: 'wb-marketplace-high-level-data-contracts',
  parent: rootRouteRef,
  path: '/data-contracts-poc',
});

export const marketplaceSearchRef = createSubRouteRef({
  id: 'wb-marketplace-search',
  parent: rootRouteRef,
  path: '/search',
});

export const discoveryZoomInRouteRef = createSubRouteRef({
  id: 'wb-marketplace:system',
  parent: rootRouteRef,
  path: '/visual-discovery/:id/*',
});
